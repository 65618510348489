<template>
    <div class="mx-md-1">
        <b-button @click="changeLange(locales[+!lang].locale)" class="btn-icon p-1 text-white" variant="flat-secondary" style="font-size: 12px">
            {{locales[+!lang].name}}
        </b-button>
    </div>
</template>
<style lang="scss">
.dropdown-language {
    .dropdown-menu {
        min-width: 77px;
    }
}
</style>
<script>
import store from '@/store'
import { getLang, setLang } from "@/libs/i18n/index.js";
import globalEvent from "@/libs/global-event" 
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            lang: state => +state.app.lang
        })
    },
    setup() {
        const locales = [
            {
                locale: "ar",
                name: "AR",
                dir: "rtl"
            },
            {
                locale: "en",
                name: "EN",
                dir: "ltr"
            }
        ];
        return {
            locales
        };
    },
    methods: {
        changeLange(locale) {
            setLang(locale)
            this.$i18n.locale = locale
            store.commit('appConfig/TOGGLE_RTL')
            globalEvent.changeLang();
        }
    },
    mounted() {
        this.changeLange(getLang())
    }
};
</script>
